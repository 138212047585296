@import "../../styles/base/variable.scss";
@import "../../styles/base/overall.scss";
@import "../../styles/base/responsiveMixin.scss";
.about-section {
  margin-top: 0px;
  margin-bottom: 0px;

  @include atMedium {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  h2 {
    color: $mainDarkWord;
  }

  &-row {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    margin: auto;

    @include atMedium {
      flex-direction: row;
      justify-content: space-around;
    }
  }

  .about-info {
    // background-color: green;
    flex: 1;
    width: 100%;
    margin: auto;
    margin-top: 80px;
    text-align: center;
    // background-color: gold;

    @include atMedium {
      margin-top: auto;
    }
  }

  .about-info-img {
    // background-color: yellow;
    flex: 1;
    width: 100%;
    margin: auto;
    // background-color: yellow;

    &__img {
      background-image: url("../../images/About.jpg");
      width: 250px;
      height: 250px;
      border-radius: 125px;
      background-size: cover;
      margin: 30px auto 30px auto;

      @include atSmall {
        width: 500px;
        height: 500px;
        border-radius: 250px;
      }
    }
  }
}
