@import "../../../styles/base/variable.scss";
@import "../../../styles/base/responsiveMixin.scss";
.skill {
  width: 80px;
  height: 100px;
  margin: 5px;

  background-color: rgba($mainDark, $alpha: 0.3);
  border-radius: 10px;
  padding: none;
  padding-top: 2px;
  transform: scale(1);

  &-name {
    font-size: 15px;
    margin-bottom: 0;
    padding: none;
  }

  &-logo {
    margin: none;
  }

  @include atSmall {
    width: 80px;
    height: 100px;
    margin: 20px;

    &-name {
      font-size: 18px;
      margin-bottom: 0;
      padding: none;
    }

    &-logo {
      margin: none;
    }
  }

  &:hover {
    transform: scale(1.1);
    transition: all 0.5s ease-in-out;
  }
}
