.projects-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.projects-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;

  // background-color: green;
}
