@import "../../styles/base/variable.scss";
@import "../../styles/base/responsiveMixin.scss";
.motto-section {
  position: relative;
  width: 100%;
  height: 50vh;
  display: table;
  z-index: -1;
  // background-color: $mainDark;

  background-image: url("../../images/motto-bg.jpeg");
  background-attachment: fixed;
  background-size: cover;

  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.motto-code {
  font-size: 1.7rem;
  z-index: 10;
  text-shadow: 0.1rem 0.1rem 0.8rem black;
  @include atMedium {
    font-size: 2.3rem;
  }
}
