@import "../../styles/base/variable.scss";
@import "../../styles/base/responsiveMixin.scss";

.hero-section {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: -1;
  text-align: center;

  &__bg {
    height: 100%;
    width: 100%;
    background-image: url("../../images/hero-section__bg.jpg");
    background-size: cover;
    transition: all 0.5s ease-in;
    @include atLarge {
    }
  }
  &-2__bg {
    height: 100%;
    width: 100%;
    background-image: url("../../images/hero2.png");
    background-size: cover;
    transition: all 0.5s ease-in;
    @include atLarge {
    }
  }

  &__text-content {
    flex: 1;
    position: relative;
    margin: auto;
    width: 100%;

    background-color: rgba(255, 255, 255, 0.6);

    h1 {
      font-size: 2.2rem;
      color: $mainDarkWord;
    }

    h2 {
      font-size: 1.8rem;
    }
    h3 {
      font-size: 40px;
      color: $mainDarkWord;
    }

    p {
      font-size: 1.1rem;
      padding: 15px;
    }

    @include atMedium {
      margin-top: auto;
      margin-buttom: auto;
      h1 {
        font-size: 80px;
      }

      h2 {
        font-size: 2rem;
      }

      h3 {
        font-size: 50px;
        color: $mainDarkWord;
      }

      p {
        font-size: 1.5rem;
      }
    }
  }

  .hero-section--all {
    position: absolute;

    // background-color: yellowgreen;
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-around;
  }

  .hero-section__text-content-2 {
    flex: 1;
    display: none;
    @include atSmall {
      display: block;
    }
  }
}
