@import "../../styles/base/variable.scss";
@import "../../styles/base/overall.scss";
@import "../../styles/base/responsiveMixin.scss";

.skills-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-top: 50px;
  padding-bottom: 60px;
  // background-color: red;
  background: linear-gradient(
      to bottom,
      rgba(41, 41, 41, 0.82),
      rgba(46, 42, 111, 0.53)
    ),
    url("../../images/skills-bg.jpeg");
  background-size: cover;
  background-color: rgba(0, 0, 0, 0.8);

  color: $mainWhite;

  .skill-tools {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include atMedium {
      flex-direction: row;
      justify-content: space-around;
    }

    .develop-tools {
      width: 80%;
      height: 100%;
      right: 10px;
      margin: auto;

      @include atMedium {
        width: 100%;
        rignt: 300px;
      }
    }

    .other-tools {
      // background-color: yellow;
      width: 100%;
      margin: auto;
      .full-stack-media-container {
        width: 100%;

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;

        @include atMedium {
          flex-direction: column;
        }
      }
    }
  }
  .skillContainer {
    padding-top: 0;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;

    @include atMedium {
    }
  }

  .skillRowContainer {
    // background-color: greenyellow;

    width: 50%;

    h2 {
      margin-bottom: 15px;
    }

    li {
      list-style-type: none;
      font-size: 1.2rem;
      &:hover {
        transform: scale(1.1);
        transition: all 0.5s ease-in-out;
      }
    }
  }
}
