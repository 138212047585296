@import "../../styles/base/variable.scss";

.footer-section {
  background-color: $mainDark;
  color: $mainWhite;
  width: 100%;
  padding: 2rem;
  text-align: center;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  > h2 {
    font-size: 3rem;
    margin-top: 2px;
    margin-bottom: 2px;
  }

  .footer-row {
    width: 60%;
    margin: auto;
    display: flex;
    flex-direction: row;
    text-align: center;
    justify-content: space-around;
  }

  hr.light {
    border-top: 1px solid $mainWhite;
    width: 75%;
    margin-top: 0.8rem;
    margin-bottom: 1rem;
  }
  //   a {
  //     color: #d5d5d5;
  //   }
  hr.light-100 {
    border-top: 1px solid $mainWhite;
    width: 100%;
    margin-top: 0.8rem;
    margin-bottom: 1rem;
  }
  h5 {
    text-align: center;
  }
}
